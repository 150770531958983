import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { createProject } from '../../../features/sessions/projectsManagement/sessionManageProjects';
import './createProjectForm.css';
import LoadingModal from '../../modal/loadingModal/LoadingModal';
import { toast } from 'react-toastify';

export default function CreateProjectForm(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const [privacityFormBody, setPrivacityFormBody] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);
    const [titleForm, setTitleForm] = useState('');
    const [descriptionForm, setDescriptionForm] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const [createButtonClicked, setCreateButtonClicked] = useState(false)
    const loading = false

    async function handleCreateProject(event: React.FormEvent<HTMLFormElement>){
        event.preventDefault();

        const postBody = {
            title: titleForm,
            description: descriptionForm,
            privacity: privacityFormBody
            }
        
        if (authHeaders && currentUser && postBody){
            const response = await dispatch(createProject({authHeaders,currentUser,postBody}))
            if (response.payload.errors && response.payload.errors.length > 0 ){
                response.payload.errors.map((item: string) => {toast.error(item)})
            } 

            setCreateButtonClicked(false)
        }
    }

    return(
        <>
            {
                loading ?
                <LoadingModal/>
            :
            <div id='container-form-labels-create-project'>
            {createButtonClicked == true ?
                <form id='container-form-create-project' onSubmit={handleCreateProject} encType="multipart/form-data">
                    <h3 style={{ display: "flex", marginTop: "0px", marginBottom: "5px" }}>Create Project</h3>
                    
                    <div id='container-title-project-form'>
                        <label>Title</label>
                        <input type="text" placeholder='project title' value={titleForm} onChange={(event) => { setTitleForm(event.target.value) }}></input>
                    </div>

                    <div id='container-description-project-form'>
                        <label>Description</label>
                        <textarea placeholder='Description' value={descriptionForm} onChange={(event) => { setDescriptionForm(event.target.value) }}></textarea>
                    </div>

                        <div id='container-project-privacity'>
                            <label>Project Privacity</label>
                                <div id='project-privacity-form'>
                                    <input
                                    type='radio'
                                    name='radio'
                                    value={1}
                                    onChange={() => { setPrivacityFormBody(0); setErrors([]); }}
                                    checked={privacityFormBody === 0}
                                    />
                                    <span>Public</span>

                                    <input
                                    type="radio"
                                    name="radio"
                                    value={2}
                                    onChange={() => { setPrivacityFormBody(1); setErrors([]); }}
                                    checked={privacityFormBody === 1}
                                    />
                                    <span>Private</span>
                                </div>
                            <button type='submit' id='button-create-project'>CREATE</button>
                        </div>
                </form>
            :
                <button id='button-open-project-form'
                onClick={() => {setCreateButtonClicked(!createButtonClicked)}}>
                    + Create project
                </button>
            }
            </div>    
        }
        </>
    )
}
