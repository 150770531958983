import React, { useEffect } from 'react';
import './addRemoveUserMusicalGenres.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { createUserMusicalGenre, deleteUserMusicalGenre, getMusicalGenresAvailable, getUserMusicalGenres } from '../../../../features/sessions/sessionProfileNavigation';

export default function AddRemoveUserMusicalGenres({userNickname,onClose}:any){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const musicGenresAvailable = useSelector((state: RootState) => state.sessionProfile.musicalGenresAvailable);
    const userMusicGenres = useSelector((state: RootState) => state.sessionProfile.userMusicalGenres);

    
    useEffect(() => {

        async function fetchMusicalGenresAvailable() {
            if (authHeaders){
                const response = await dispatch(getMusicalGenresAvailable({authHeaders: authHeaders}))
            }
        }


        function fetchUserRouteMusicalGenres(){
            if (authHeaders && userNickname){
                const response = dispatch(getUserMusicalGenres({headers: authHeaders, 
                                                                urlParams: {
                                                                    userNickname: userNickname}
                                                                }
                                                              )
                                         )
            }
        }

        // async function fetchUserInstruments() {
        //     if (authHeaders){
        //         const response = await dispatch(getUserInstruments({
        //                                                             headers: authHeaders,
        //                                                             urlParams:{
        //                                                                 userNickname: userNickname
        //                                                             }
        //                                                            }
        //                                                           )
        //                                                         )
        //                                                     }
                                                                
        //                                                 }
        
        // fetchUserInstruments();
        fetchMusicalGenresAvailable();

    },[])

    async function handleCreateUserMusicGenre(musicalGenreId: string)
    {

        if (authHeaders && userNickname)
        {
            const response = await dispatch(createUserMusicalGenre(
                                                                 {headers: authHeaders,
                                                                  urlParams: {
                                                                    userNickname: userNickname
                                                                  },
                                                                  requestBodyData: {
                                                                    musicGenreId: musicalGenreId
                                                                  }
                                                                 }
                                                                )
                                            )
        }
    }

    async function handleDeleteUserMusicGenre(musicGenreId: string, userMusicalGenreId: string){
        
        if (authHeaders && userNickname)
            {
                const response = await dispatch(deleteUserMusicalGenre(
                                                                     {headers: authHeaders,
                                                                      urlParams: {
                                                                        userNickname: userNickname,
                                                                        musicGenreId: musicGenreId,
                                                                        userMusicalGenreId: userMusicalGenreId
                                                                      }
                                                                     }
                                                                    )
                                                )
            }
    }

    return(
            <>
                <div id='container-add-remove-user-musical-genres-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-musical-genres-modal'>
                        <div id='container-add-remove-user-musical-genres-modal'>
                            <div id='container-top-add-remove-user-musical-genres-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-musical-genres-modal'>
                            <ul>
                                    {musicGenresAvailable && musicGenresAvailable.map((availableMusicGenre, index) => {
                                        const isSelected = userMusicGenres && userMusicGenres.some(userMusicGenre => userMusicGenre && userMusicGenre.musicGenreId === availableMusicGenre.id);
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-music-genres-already-selected' 
                                                key={`${index}-selected`} 
                                                onClick={() => {
                                                    const selectedUserMusicGenre = userMusicGenres && userMusicGenres.find(userMusicGenre => userMusicGenre && userMusicGenre.musicGenreId === availableMusicGenre.id);
                                                    if (selectedUserMusicGenre) {
                                                        handleDeleteUserMusicGenre(`${availableMusicGenre.id}`,`${selectedUserMusicGenre.id}`);
                                                    }
                                                }}>
                                                <h3>
                                                    {availableMusicGenre.description}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-music-genres-available' 
                                                key={`${index}-available`} 
                                                onClick={() => handleCreateUserMusicGenre(`${availableMusicGenre.id}`)}>
                                                <h3>
                                                    {availableMusicGenre.description}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}