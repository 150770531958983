import React, { useMemo } from 'react';
import LoadingAnimation from '../../../app/components/modal/LoadingAnimation';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { useEffect, useState } from 'react';
import { authenticateUserFromStorage } from '../../../app/features/sessions/sessionSlice';
import './contents.css';
import { ContentPlayer } from '../../../app/components/audioPlayer/WaveSurfer/contentPlayer/ContentPlayer';
import { getInstruments, getUserContents } from '../../../app/features/sessions/sessionAudioContent/sliceUserContents';
import MyContentsUploadForm from '../../../app/components/forms/contents/MyContentsUploadForm'; 
import classNames from 'classnames';
import LoadingModal from '../../../app/components/modal/loadingModal/LoadingModal';
import { useParams } from 'react-router-dom';
import MoreDetailsIcon from '../../../app/assets/more-details-white.png';
import { AuthHeaders, Content } from '../../../app/#interfaces/interfaces';
import { CircularProgress } from '@mui/material';
import ContentShowEdit from '../../../app/components/modal/contentModal/ContentShowEdit';

export default function Contents(){
    const loading = useSelector((state: RootState) => state.sessionUserContents.loading);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const dispatch = useDispatch<AppDispatch>();
    const contents = useSelector((state: RootState) => state.sessionUserContents.userContents);
    const [messages, setSuccessMessages] = useState<string[]>([]);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const contentsPosted = useSelector((state: RootState) => state.sessionUserContents.newMyContents);
    const noContentsCondition = (contents && contents.length === 0) && (contentsPosted && contentsPosted.length === 0);
    const containerClasses = classNames('contents-container', {
        'no-contents-style': noContentsCondition,
    });
    const { userNicknameRoute } = useParams();
    const MemorizedContentPlayer = useMemo(() => React.memo(ContentPlayer), []);
    const [contentsPage, setContentsPage] = useState(1);
    const loadingMoreContents = useSelector((state: RootState) => state.sessionUserContents.loadingMoreContents);
    const loadingCreateContent = useSelector((state: RootState) => state.sessionUserContents.loadingCreateContent);
    const [modalContentEditShowOpen,setModalContentEditShowOpen] = useState(false)
    const [selectedContentObj, setSelectedContentObj] = useState<Content>();
    const [selectedContentOwnerNickname, setSelectedContentOwnerNickname] = useState<string>();

    const fetchContents = async () => {
        if (authHeaders){
            await dispatch(getUserContents({
                authHeaders: authHeaders,
                routeParams: {
                    userNickname: userNicknameRoute,
                    contentsPage: contentsPage
                }
            }));
        }
    }

    useEffect(() => {
        fetchContents();
    }, [contentsPage]);


    useEffect(() => {
        
        async function handleInstrumentsComboBox() {
            await dispatch(getInstruments());
        }

        handleInstrumentsComboBox();

    }, []);
    
    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('container-ul-my-contents');
            if (!container) return;

            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight && !loadingMoreContents) {
                setContentsPage(contentsPage + 1);
            }
        };

        setTimeout(() => {
            const container = document.getElementById('container-ul-my-contents');
            if (container){
                container.addEventListener('scroll', handleScroll);
                return () => container.removeEventListener('scroll', handleScroll);
            }
        }, 100);
    }, [loadingMoreContents]);

    const handleFileChange = (event: any) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    };
    
    function handleOpenContentModal(contentObj: Content){
        setSelectedContentObj(contentObj)
        setModalContentEditShowOpen(true)
    }

    return (
        <div id='container-page-contents'>
            {
                modalContentEditShowOpen === true ?

                <ContentShowEdit contentObj={selectedContentObj}
                                 onCloseClick={() => setModalContentEditShowOpen(false)}/>

                :

                null
            }
            <>
                <div id='container-contents-left'>

                </div>

                <div id='container-center-contents'>
                    <div id='container-form-contents'>
                        {currentUser?.nickname === userNicknameRoute ?
                            <MyContentsUploadForm />
                            :
                            null
                        }
                        <div id='my-contents-container'>
                            <div className='container-title-my-contents'>
                                {
                                    userNicknameRoute === currentUser?.nickname 
                                    
                                    ?

                                    <div className='container-letters-title'>
                                        <div className="letter">M</div>
                                        <div className="letter">y</div>
                                        <div className="letter">&nbsp;</div>
                                        <div className="letter">C</div>
                                        <div className="letter">o</div>
                                        <div className="letter">n</div>
                                        <div className="letter">t</div>
                                        <div className="letter">e</div>
                                        <div className="letter">n</div>
                                        <div className="letter">t</div>
                                        <div className="letter">s</div>
                                    </div>

                                    :

                                    <div className='container-letters-title'>
                                        <div className="letter">U</div>
                                        <div className="letter">s</div>
                                        <div className="letter">e</div>
                                        <div className="letter">r</div>
                                        <div className="letter">&nbsp;</div>
                                        <div className="letter">C</div>
                                        <div className="letter">o</div>
                                        <div className="letter">n</div>
                                        <div className="letter">t</div>
                                        <div className="letter">e</div>
                                        <div className="letter">n</div>
                                        <div className="letter">t</div>
                                        <div className="letter">s</div>
                                    </div>
                                }
                            </div> 
                            <div id='contents-container'>
                                    <div id='container-ul-my-contents'>
                                        {loadingCreateContent && contentsPage === 1

                                            ?

                                            <div id='container-loading-spineer'>
                                                <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                            </div>

                                            :

                                            null
                                        }
                                        {/* {contentsPosted.length > 0 ?
                                            <ul id='ul-my-contents'>
                                            {contentsPosted.length > 0 && contentsPosted.map((item, index) => (
                                                <li id='li-my-contents' key={index}>
                                                    <div id="container-instrument-name">
                                                        {item.instrument ?
                                                            <h5>{item.instrument && item.instrument.name}</h5>
                                                            :
                                                            <h5>other</h5>
                                                        }

                                                    </div>
                                                    <div id="container-title-player">
                                                        <div id="container-title-content">
                                                            <div id="container-title-content-h5">
                                                                <h5 className='title-content-h5'>
                                                                    {item.title}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className='container-wave-surfer-player'>
                                                            <MemorizedContentPlayer
                                                                fileUrl={`${item.fileUrl}`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id='container-my-contents-buttons'>
                                                        <button id='more-details-my-content'
                                                            onClick={() => { console.log('edit-button') }}>
                                                            <img src={MoreDetailsIcon} />
                                                        </button>
                                                    </div>
                                                </li>
                                            ))
                                            }

                                            </ul>
                                            
                                            :

                                            null
                                            } */}

                                            <ul id='ul-my-contents'>
                                                {contents.length > 0 && contents.map((item, index) => (
                                                    <li id='li-my-contents' key={index}>
                                                        <div id="container-instrument-name">
                                                            {item.instrument ?
                                                                <h5>{item.instrument && item.instrument.name}</h5>
                                                                :
                                                                <h5>other</h5>
                                                            }

                                                        </div>
                                                        <div id="container-title-player">
                                                            <div id="container-title-content">
                                                                <div id="container-title-content-h5">
                                                                    <h5 className='title-content-h5'>
                                                                        {item.title}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div className='container-wave-surfer-player'>
                                                                <MemorizedContentPlayer
                                                                    fileUrl={`${item.fileUrl}`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id='container-my-contents-buttons'>
                                                            <button id='more-details-my-content'
                                                                onClick={() => handleOpenContentModal(item)}>
                                                                <img src={MoreDetailsIcon} />
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))
                                                }
                                                {loadingMoreContents
        
                                                    ?
        
                                                    <div id='container-loading-spineer'>
                                                        <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                                    </div>
        
                                                    :
        
                                                    null
                                                }

                                            </ul>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id='container-contents-right'>

                </div>
            </> 

        </div>
    )
}