import React, { useEffect } from 'react';
import './addRemoveUserInstruments.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { createUserInstrument, deleteUserInstrument, getInstruments, getUserInstruments } from '../../../../features/sessions/sessionProfileNavigation';
import { Instrument, UserInstrument } from '../../../../#interfaces/interfaces';

export default function AddRemoveUserInstruments({userNickname,onClose}:any){
    const instrumentsAvailable = useSelector((state: RootState) => state.sessionProfile.instrumentsAvailable);
    const userInstruments = useSelector((state: RootState) => state.sessionProfile.userInstruments);
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    
    useEffect(() => {

        async function fetchInstrumentsAvailable() {
            const response = await dispatch(getInstruments())
        }

        async function fetchUserInstruments() {
            if (authHeaders){
                const response = await dispatch(getUserInstruments({
                                                                    headers: authHeaders,
                                                                    urlParams:{
                                                                        userNickname: userNickname
                                                                    }
                                                                   }
                                                                  )
                                                                )
                                                            }
                                                                
                                                        }
        
        fetchUserInstruments();
        fetchInstrumentsAvailable();

    },[])

    async function handleCreateUserInstrument(instrumentId: string)
    {

        if (authHeaders && userNickname)
        {
            const response = await dispatch(createUserInstrument(
                                                                 {headers: authHeaders,
                                                                  urlParams: {
                                                                    userNickname: userNickname
                                                                  },
                                                                  requestBodyData: {
                                                                    instrumentId: instrumentId
                                                                  }
                                                                 }
                                                                )
                                            )
        }
    }

    async function handleDeleteUserInstrument(instrumentId: string, userInstrumentId: string){
        if (authHeaders && userNickname)
            {
                const response = await dispatch(deleteUserInstrument(
                                                                     {headers: authHeaders,
                                                                      urlParams: {
                                                                        userNickname: userNickname,
                                                                        instrumentId: instrumentId,
                                                                        userInstrumentId: userInstrumentId
                                                                      }
                                                                     }
                                                                    )
                                                )
            }
    }

    return(
            <>
                <div id='container-add-remove-user-instruments-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-instruments-modal'>
                        <div id='container-add-remove-user-instruments-modal'>
                            <div id='container-top-add-remove-user-instruments-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-instruments-modal'>
                                <ul>
                                    {instrumentsAvailable && instrumentsAvailable.map((availableInstrument, index) => {
                                        const isSelected = userInstruments && userInstruments.some(userInstrument => userInstrument && userInstrument.instrumentId === availableInstrument.id);
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-instruments-already-selected'
                                                key={`${index}-selected`} 
                                                onClick={() => {
                                                    const selectedUserInstrument = userInstruments && userInstruments.find(userInstrument => userInstrument && userInstrument.instrumentId === availableInstrument.id);
                                                    if (selectedUserInstrument) {
                                                        handleDeleteUserInstrument(`${availableInstrument.id}`,`${selectedUserInstrument.id}`);
                                                    }
                                                }}>
                                                <h3>
                                                    {availableInstrument.name}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-instruments-available'
                                                key={`${index}-available`} 
                                                onClick={() => handleCreateUserInstrument(`${availableInstrument.id}`)}>
                                                <h3>
                                                    {availableInstrument.name}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}