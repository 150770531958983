import React from 'react';
import LoadingAnimation from '../LoadingAnimation';
import './loadingModal.css';

function LoadingModal() {
    
    return(
        <div id='container-loading-modal'>
            <LoadingAnimation/>
        </div>
    )
}

export default LoadingModal;