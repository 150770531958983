import React, { useMemo } from 'react';
import { useEffect, useRef, useState, KeyboardEvent, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../app/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectContentPlayer } from '../../../../app/components/audioPlayer/WaveSurfer/waveSurferProjectContent/ProjectContentPlayer';
import * as REQUEST_REQUIREMENTS from '../../../../app/api/requestRequirements';
import { toast } from 'react-toastify';
import { authenticateUserFromStorage } from '../../../../app/features/sessions/sessionSlice';
import { getProjectContents, convertKeysToCamelCase, patchCurrentProject, postProjectFile, takeProjectContentFromProject, refreshProjectContents } from '../../../../app/features/sessions/projectsManagement/sessionManageProject';
import MixProjectContents from './MixProjectContents';
import { clearSelection, createProjectContentComment } from '../../../../app/features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer';
import { formatDuration } from '../../../../app/components/audioPlayer/WaveSurfer/waveSurferProjectContent/Duration';
import { FormCurrentProject } from './FormCurrentProject';
import { Switch as MuiSwitch, ThemeProvider, createTheme } from '@mui/material';
import { EditMinutesSecondsCentiseconds } from '../../../../app/components/inputs/EditMinutesSecondsCentiseconds';
import { ButtonSelectAllUseful } from '../../../../app/components/audioPlayer/WaveSurfer/waveSurferProjectContent/ProjectContentsSelection';
import { ProjectPlayer } from '../../../../app/components/audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer';
import editIcon from '../../../../../src/app/assets/edit-icon-white.png';
import visitorsIcon from '../../../../../src/app/assets/visitors.png';
import mergeIcon from '../../../../../src/app/assets/burn.png'
import './manageProject.css';
import LoadingModalScreenContainer from '../../../../app/components/modal/loadingModalScreenContainer/LoadingModalScreenContainer';
import LoadingModal from '../../../../app/components/modal/loadingModal/LoadingModal';
import { ProjectContentInfo } from '../../../../app/components/modal/projectContent/info/ProjectContentInfo';
import { YesNoDialogModal } from '../../../../app/components/modal/dialogBox/group/yesNo/YesNoDialogModal';
import { ProjectContentCommentsModal } from '../../../../app/components/modal/projectContent/comments/ProjectContentCommentsModal';
import AudioContentRecorder from './AudioContentRecorder';
import CreateAudioContentRecorded from '../../../../app/components/modal/createAudioContentRecorded/CreateAudioContentRecorded';
import { SkeletonLoading } from '../../../../app/components/skeletonLoading/SkeletonLoading';

const theme = createTheme({
    palette: {
      primary: {
        main: '#239400',
      },
      secondary: {
        main: '#e70000',
      }
    },
  });

export default function ManageProject(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const currentUsefulProjectContents = useSelector((state : RootState) => state.sessionManageProjectContents.usefulProjectContents);
    const currentUselessProjectContents = useSelector((state : RootState) => state.sessionManageProjectContents.uselessProjectContents);
    const newUsefulProjectContents = useSelector((state : RootState) => state.sessionManageProjectContents.newUsefulProjectContents);
    const newUselessProjectContents = useSelector((state : RootState) => state.sessionManageProjectContents.newUselessProjectContents);
    const loading = useSelector((state: RootState) => state.sessionProjectPlayerManagement.loading || 
                                                      state.sessionManageProjectContents.loading);
    const projectDuration = useSelector((state : RootState) => state.sessionManageProjectContents.currentProject.project.projectDuration );
    const projectDurationFormated = formatDuration(projectDuration)
    const currentProject = useSelector((state : RootState) => state.sessionManageProjectContents.currentProject.project);
    const projectContentSelectedList = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentSelectedList);
    const errors = useSelector((state : RootState) => state.sessionManageProjectContents.errors);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, projectIdRoute } = useParams();
    const [ editTitleClicked, setEditTitleClicked ] = useState(false);
    const currentProjectPrivacity = useSelector((state: RootState) => state.sessionManageProjectContents.currentProject.project.privacity);
    const [currentProjectIsPublic, setCurrentProjectIsPublic] = useState(currentProjectPrivacity == 0 ? true : false);
    const [mixedAudioUrl, setMixedAudioUrl] = useState<string | undefined>('');
    const masterUrl = useSelector((state: RootState) => state.sessionManageProjectContents.masterFiles);
    const loadingScreenUseless = useSelector((state : RootState) => state.sessionManageProjectContents.loadingScreenUseless);
    const loadingScreenUseful = useSelector((state : RootState) => state.sessionManageProjectContents.loadingScreenUseful);
    const mergedAudioUrl = useSelector((state: RootState) => state.sessionProjectPlayerManagement.mergedAudioUrl);
    const MemorizedProjectContentPlayer = useMemo(() => React.memo(ProjectContentPlayer), []);
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const [projectContentIdInfoSelected,setProjectContentIdInfoSelected] = useState<string>('');
    const [showProjectContentInfoModal,setShowProjectContentInfoModal] = useState<boolean>(false);
    const [openYesNoRemoveContentModal,setOpenYesNoRemoveContentModal] = useState<boolean>(false);
    const [projectContentToDelete,setProjectContentToDelete] = useState<string>('');
    const [projectContentToComment,setProjectContentToComment] = useState<string>('');
    const [showProjectContentCommentModal,setShowProjectContentCommentModal] = useState<boolean>(false);
    const loadingProjectContentsCommments = useSelector((state: RootState) => state.sessionProjectPlayerManagement.loadingProjectContentsCommments);
    const [showFormAudioRecordedCreation, setShowFormAudioRecordedCreation] = useState(false);
    const [audioRecordedUrl, setAudioRecordedUrl] = useState<string | null>(null);
    const somethingSelected = useSelector((state: RootState) => state.sessionManageProjectContents.somethingSelected);
    const pageLoadedOnceTime = useSelector((state: RootState) => state.sessionManageProjectContents.pageLoadedOnceTime);
    
    const handleOpenRecorderModal: React.Dispatch<React.SetStateAction<boolean>> = (isOpen) => {
        setShowFormAudioRecordedCreation(isOpen);
    };

    useEffect(() => {
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        async function getCurrentPojectContents(){

            if (projectIdRoute != undefined){
                const response = await dispatch(getProjectContents({authHeaders: authHeaders, userNickname: userNicknameRoute, projectId: projectIdRoute}))

                if (response.payload.status <200 || response.payload.status >= 300){

                    const routeParams={
                        userNickname: currentUser?.nickname
                    }
                    
                    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
            
                    if (PRIVATE_ROUTES){
                        if (response.payload.status >= 500 ) {
                            toast.error("Internal Server Error")
                        }
                        else {
                            toast.error(errors)
                        }
                        navigate(PRIVATE_ROUTES.HOME)
                    }
                } 
            }
        }

        dispatch(clearSelection());
        authenticateUser();
        getCurrentPojectContents();
        setCurrentProjectIsPublic(currentProjectPrivacity == 0 ? true : false);

    },[userNicknameRoute,currentProjectIsPublic])


    useEffect(() => {
        async function authenticateUser() {
            if (!userValidated) {
              await dispatch(authenticateUserFromStorage());
            }
        }

        async function getCurrentPojectContents(){

            if (projectIdRoute != undefined){
                const response = await dispatch(refreshProjectContents({authHeaders: authHeaders, userNickname: userNicknameRoute, projectId: projectIdRoute}))

                if (response.payload.status <200 || response.payload.status >= 300){

                    const routeParams={
                        userNickname: currentUser?.nickname
                    }
                    
                    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});
            
                    if (PRIVATE_ROUTES){
                        if (response.payload.status >= 500 ) {
                            toast.error("Internal Server Error")
                        }
                        else {
                            toast.error(errors)
                        }
                        navigate(PRIVATE_ROUTES.HOME)
                    }
                } 
            }
        }

        // dispatch(clearSelection());
        authenticateUser();
        getCurrentPojectContents();
        // setCurrentProjectIsPublic(currentProjectPrivacity == 0 ? true : false);

    },[userNicknameRoute,currentProjectIsPublic,somethingSelected])

    async function handlePrivacity() {
        const response =  await dispatch(patchCurrentProject({  authHeaders: authHeaders,
                                                                projectRouteParams: {
                                                                                        userNicknameRoute: userNicknameRoute, 
                                                                                        projectIdRoute: projectIdRoute
                                                                                    },
                                                                                    patchCurrentProjectBody: {privacity: currentProjectPrivacity == 0 ? 1 : 0}}));
    }

    function handleEditTitleClicked(){
        setEditTitleClicked(!editTitleClicked);
    }

    const handleKeyDownDuration = (event: KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        };
    
    async function handleCommitSketch(){
        const response =  await dispatch(postProjectFile({  authHeaders: authHeaders,
                                                            projectRouteParams: {
                                                                                userNicknameRoute: userNicknameRoute, 
                                                                                projectIdRoute: projectIdRoute
                                                                                },
                                                                                postCurrentProjectFileBody: {id: projectIdRoute,
                                                                                                            url: mixedAudioUrl}}));
                                                    }
          
    function handleRemoveFromProject(){

        setOpenYesNoRemoveContentModal(false);

        dispatch(takeProjectContentFromProject({authHeaders: authHeaders,
                                             projectContentRouteParams: {
                                                                          userNicknameRoute: userNicknameRoute,
                                                                          projectContentId: projectContentToDelete
                                                                        }
                                            }
        ))
    }                                        

    return(
        <div>
            {
                showProjectContentCommentModal ? (
                    <ProjectContentCommentsModal 
                        projectContentShowedId={projectContentToComment} 
                        currentUserNickname={currentUser?.nickname}
                        userNicknameRoute={userNicknameRoute}
                        onCloseClick={setShowProjectContentCommentModal}
                    />
                ) : null
            }

                    <>
                    { openYesNoRemoveContentModal ? 
                        <YesNoDialogModal message={'Do you wanna <span id="text-remove-content-from-project">remove</span> this content from the project? This action cannot be undone.'}
                                        onYesClick={handleRemoveFromProject}
                                        onCloseClick={setOpenYesNoRemoveContentModal}/>
                    :
                        null
                    }
                    </>
                    
                    
                    <>
                    { showProjectContentInfoModal && userNicknameRoute ?
                    <ProjectContentInfo projectContentId={projectContentIdInfoSelected}
                                        userNicknameUrlParams={userNicknameRoute}
                                        onCloseClick={setShowProjectContentInfoModal}/>
                    
                    :

                    null

                    }
                    </>
                
                    <>
                    { showFormAudioRecordedCreation?
                        <CreateAudioContentRecorded onClose={setShowFormAudioRecordedCreation}
                                                    audioRecorded={audioRecordedUrl}/>
                        :
                        null
                    }
                    </>

            {
                loading ?

                <LoadingModal/>
                
                :

                <>
                    {somethingSelected && projectContentSelectedList.length > 0 ?
                        <MixProjectContents setMixedAudioUrl={setMixedAudioUrl}/>: null
                    }
                    <div id='container-record-button-manage-project'>
                        <AudioContentRecorder onOpenRecorderModal={handleOpenRecorderModal}
                                              onAudioRecordedUrl={setAudioRecordedUrl}/>
                    </div>
                    <div id='container-useful-useless'>
                        <div id='container-useful-contents'>
                            <div id='container-top-manage-project'>
                                <div id='container-title-line'>
                                    {!editTitleClicked ?
                                        
                                        <div id='container-title'>
                                            <h1 id='project-name'>
                                                    {currentProject.title}
                                            </h1> 
                                        </div>
                                        :
                                        <FormCurrentProject handleEditTitleClicked={handleEditTitleClicked}/>     
                                    }
                                    { currentUser && currentProject && currentUser.id == currentProject.userId ? 
                                    <button id='edit-project-title-button' onClick={handleEditTitleClicked}>
                                        <img src={editIcon}/>
                                    </button>: null 
                                    }
                                </div> 
                                <div id='container-visualisations-privacity'>
                                    <div id='container-number-label-project-visitors'>
                                        <img src={visitorsIcon} style={{width: "30px", height: "30px"}}/>
                                        <h4>{currentProject.nVisits}</h4>
                                    </div>

                                    <div className='container-button-project-privacity' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        
                                        <div>
                                            <h3 id='public-description-project-management' style={{ fontStyle: 'italic', 
                                                                                                    fontFamily: 'Calibre, sans-serif', 
                                                                                                    textTransform: 'none', 
                                                                                                    fontSize: '1rem', 
                                                                                                    color: '#e70000' }}>
                                                PRIVATE
                                            </h3>
                                        </div>
                                        <div>
                                            <ThemeProvider theme={theme}>
                                                <MuiSwitch
                                                    checked={currentProjectPrivacity == 0}
                                                    onChange={handlePrivacity}
                                                    color={currentProjectPrivacity == 0 ? 'primary' : 'secondary'}
                                                    disabled={currentUser && currentUser.id != currentProject.userId}
                                                />
                                            </ThemeProvider>
                                        </div>
                                        <div>
                                            <h3 id='public-description-project-management' style={{ fontStyle: 'italic', fontFamily: 'Calibre, sans-serif', textTransform: 'none', fontSize: '1rem', color: '#239400' }}>
                                                PUBLIC
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='container-play-project'>
                                <div id='container-top-tracks'>
                                    {masterUrl.length != 0 ?
                                    <div id="container-master-track">
                                        <div id="container-project-player-master-mixed">
                                            <MemorizedProjectPlayer
                                                fileUrl={`${masterUrl[0].signedUrl}`}/>
                                        </div>
                                    </div> 
                                    : null }
                                    {mixedAudioUrl != '' ? 
                                    <div id="container-sketch-track">
                                        <div id="container-project-player-sketch-mixed">
                                            <MemorizedProjectPlayer
                                                fileUrl={mergedAudioUrl}/>
                                        </div>
                                        {currentUser && currentProject.userId == currentUser.id ?
                                                <button id='button-commit-sketch' onClick={handleCommitSketch}>
                                                    <img src={mergeIcon}/>
                                                </button>
                                            : null
                                        }
                                    </div> 
                                    : null}
                                </div>
                            </div>
                            <div id='container-project-duration'>
                                    <EditMinutesSecondsCentiseconds projectDurationFormated={projectDurationFormated}
                                                                    currentProject={currentProject}/>
                            </div>
                            <div id='container-select-all'>
                                <ButtonSelectAllUseful/>
                            </div>
                            <div id='container-title-ul'>
                                <div id='container-title-useful-contents'>
                                    <div id='useful-contents-letters-title'>
                                        <div id='letter-useful-contents'>U</div>
                                        <div id='letter-useful-contents'>s</div>
                                        <div id='letter-useful-contents'>e</div>
                                        <div id='letter-useful-contents'>f</div>
                                        <div id='letter-useful-contents'>u</div>
                                        <div id='letter-useful-contents'>l</div>
                                        <div id='letter-useful-contents'>&nbsp;</div>
                                        <div id='letter-useful-contents'>c</div>
                                        <div id='letter-useful-contents'>o</div>
                                        <div id='letter-useful-contents'>n</div>
                                        <div id='letter-useful-contents'>t</div>
                                        <div id='letter-useful-contents'>e</div>
                                        <div id='letter-useful-contents'>n</div>
                                        <div id='letter-useful-contents'>t</div>
                                        <div id='letter-useful-contents'>s</div>
                                    </div>
                                </div>
                            <div id='container-ul-useful-contents'>
                                    {loadingScreenUseful ? 
                                        <LoadingModalScreenContainer/>
                                    :
                                    <ul id='unordered-list-manage-project'>
                                    {newUsefulProjectContents && newUsefulProjectContents.map((item, index) => (
                                            item.content ? (
                                                <li id='li-manage-project' key={index}>
                                                   <div id="container-project-content-instrument-name">
                                                        {
                                                            item.content.instrument?.name ?
                                                            <label>{item.content.instrument.name}</label>
                                                            :
                                                            <label>outro</label>
                                                        }
                                                    </div>
                                                    <div id='container-container-title-project-content-player' style={{
                                                                zIndex: "1"
                                                              }}>
                                                        <div id="container-player-project-content-title">
                                                            <div id='container-title-content-project-content'>
                                                                <h5 id="project-content-title">
                                                                    {item.content.title}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <MemorizedProjectContentPlayer
                                                            projectContentObj={item}
                                                            fileUrl={`${item.fileUrl}`}
                                                            startsIn={item.startsIn}
                                                            showYesNoRemoveProjectFunction={setOpenYesNoRemoveContentModal}
                                                            functionSetIdToShowInfo={setProjectContentIdInfoSelected}
                                                            functionProjectIdToRemove={setProjectContentToDelete}
                                                            setShowInfoModal={setShowProjectContentInfoModal}
                                                            boxCommentsOpened={showProjectContentCommentModal}
                                                            setShowToggleBoxComments={setShowProjectContentCommentModal}
                                                            setProjectContentToComment={setProjectContentToComment}
                                                        />
                                                    </div>
                                                </li>
                                            ) : null
                                        ))}
                                        { currentUsefulProjectContents && currentUsefulProjectContents.map((item, index) => (
                                        item.content ? (
                                            <li id='li-manage-project' key={index}>
                                                <div id="container-project-content-instrument-name">
                                                    {
                                                        item.content.instrument?.name ?
                                                        <label>{item.content.instrument.name}</label>
                                                        :
                                                        <label>outro</label>
                                                    }
                                                </div>
                                                <div id='container-container-title-project-content-player' style={{
                                                                zIndex: "1"
                                                              }}>
                                                    <div id="container-player-project-content-title">
                                                        <div id='container-title-content-project-content'>
                                                            <h5 id="project-content-title">
                                                                {item.content.title}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <MemorizedProjectContentPlayer
                                                        projectContentObj={item}
                                                        fileUrl={`${item.fileUrl}`}
                                                        startsIn={item.startsIn}
                                                        showYesNoRemoveProjectFunction={setOpenYesNoRemoveContentModal}
                                                        functionSetIdToShowInfo={setProjectContentIdInfoSelected}
                                                        functionProjectIdToRemove={setProjectContentToDelete}
                                                        setShowInfoModal={setShowProjectContentInfoModal}
                                                        boxCommentsOpened={showProjectContentCommentModal}
                                                        setShowToggleBoxComments={setShowProjectContentCommentModal}
                                                        setProjectContentToComment={setProjectContentToComment}
                                                    />
                                                </div>
                                            </li>
                                        ) : null
                                        ))}
                                    </ul>
                                }
                                </div>
                            </div>
                            <div id='container-title-ul'>
                                <div id='container-title-useless-contents'>
                                    <div id='useless-contents-letters-title'>
                                        <div id='letter-useless-contents'>&nbsp;</div>
                                    </div>
                                </div>

                                <div id='container-ul-useless-contents'>
                                {loadingScreenUseless ? 
                                    <LoadingModalScreenContainer/>
                                    :
                                    <ul id='unordered-list-manage-project'>
                                        {currentUselessProjectContents && currentUselessProjectContents.map((item, index) => (
                                            item.content ? (
                                                <li id='li-manage-project' key={index}>
                                                    <div id="container-project-content-instrument-name">
                                                            {
                                                                item.content.instrument?.name ?
                                                                <label>{item.content.instrument.name}</label>
                                                                :
                                                                <label>outro</label>
                                                            }
                                                    </div>
                                                    <div id='container-container-title-project-content-player' style={{
                                                                zIndex: "1"
                                                              }}>
                                                        <div id="container-player-project-content-title">
                                                            <div id='container-title-content-project-content'>
                                                                <h5 id="project-content-title">
                                                                    {item.content.title}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <MemorizedProjectContentPlayer
                                                            projectContentObj={item}
                                                            fileUrl={`${item.fileUrl}`}
                                                            startsIn={item.startsIn}
                                                            showYesNoRemoveProjectFunction={setOpenYesNoRemoveContentModal}
                                                            functionSetIdToShowInfo={setProjectContentIdInfoSelected}
                                                            functionProjectIdToRemove={setProjectContentToDelete}
                                                            setShowInfoModal={setShowProjectContentInfoModal}
                                                            boxCommentsOpened={showProjectContentCommentModal}
                                                            setShowToggleBoxComments={setShowProjectContentCommentModal}
                                                            setProjectContentToComment={setProjectContentToComment}
                                                        />
                                                    </div>
                                                </li>
                                            ) : null
                                        ))}
                                    </ul>
                                }
                                </div>
                            </div> 
                        </div>
                    </div>
                </>
            }
        </div>
    )
}